/* eslint-disable react/prop-types */
import React from "react";
import "react-dates/initialize";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import { backendBaseUrl } from "../../config";
import { Button } from "react-bootstrap";
import {
  getUser,
  getAllSiteAnalytics,
  getUserAnalytics,
  getTotalAnalytics,
  analyticsExportToCSV
} from "../../constants/endpoints";
import { getAuthenticatedData } from "../../utils/async";
import ReactApexChart from "react-apexcharts";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
// import { toast } from "react-toastify";
import SearchBar from "../SearchBar";
import styles from "../SearchBar/search.module.css";
import CustomExportCSVModal from "./CustomExportCSVModal";

export default class Statistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      statistics: null,
      userResults: getAuthenticatedData(backendBaseUrl + getUser),
      prevPage: null,
      nextPage: null,
      shouldDisplayPie: false,
      showModal: false,
      api: getUserAnalytics,
      apiFull: getUserAnalytics,
      startDate: null,
      minDate: moment("2020-01-01"),
      maxDate: moment().endOf("month"),
      endDate: null,
      focusedInput: null,
      seriesPie: [0, 0, 0, 0, 0, 0],
      total: {},
      totalDR: false,
      optionsPie: {
        chart: {
          type: "donut"
        },
        labels: [
          "No DR",
          "Mild NPDR",
          "Moderate NPDR",
          "Severe NPDR",
          "PDR",
          "Ungradeable"
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
      optionsBar: {
        chart: {
          type: "bar",
          toolbar: {
            show: false
          },
          height: 350
        },
        tooltip: {
          theme: "dark",
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function() {
                return "";
              }
            }
          }
        },
        colors: ["#33b2df"],
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [
            "Total Patients",
            "Total Exams",
            "Total Exams with Images",
            "Total BSL",
            "BSL over 140",
            "Self Reported Diabetic",
            "Total Exams Graded",
            "Grader Any Refer",
            "Grader DR Refer"
          ]
        }
      },
      seriesBar: [
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
      suggestions: [],
      searchParam: "",
      dateParam: "",
      showCustomExportCSVModal: false
    };

    this.closeModal = this.closeModal.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.openCustomExportCSVModal = this.openCustomExportCSVModal.bind(this);
    this.closeCustomExportCSVModal = this.closeCustomExportCSVModal.bind(this);
  }

  componentDidMount() {
    this.state.userResults
      .then(result => {
        if (
          this?.props?.location?.state?.type !== "personal" &&
          this?.props?.location?.state?.type !== "user"
        ) {
          this.setState({
            api: getAllSiteAnalytics,
            apiFull: getAllSiteAnalytics
          });
          getAuthenticatedData(backendBaseUrl + getTotalAnalytics).then(
            totals => {
              this.setState({ total: totals?.data });
            }
          );
          return Promise.resolve(
            getAuthenticatedData(backendBaseUrl + getAllSiteAnalytics)
          );
        } else {
          if (this?.props?.location?.state?.type === "user") {
            this.setState({
              api: getUserAnalytics,
              apiFull:
                getUserAnalytics + "/" + this?.props?.location?.state?.userId
            });
            return Promise.resolve(
              getAuthenticatedData(
                backendBaseUrl +
                  getUserAnalytics +
                  "/" +
                  this?.props?.location?.state?.userId
              )
            );
          } else {
            this.setState({
              api: getUserAnalytics,
              apiFull: getUserAnalytics + "/" + result?.data?.userId
            });
            return Promise.resolve(
              getAuthenticatedData(
                backendBaseUrl + getUserAnalytics + "/" + result?.data?.userId
              )
            );
          }
        }
      })
      .then(result => {
        this.setState({
          statistics: Array.isArray(result?.data) ? result?.data : null,
          seriesBar:
            typeof result?.data === "object"
              ? [
                  {
                    data: [
                      result?.data?.totalPatients,
                      result?.data?.totalExams,
                      result?.data?.totalPatientsImaged,
                      result?.data?.totalBSL,
                      result?.data?.bslOver140,
                      result?.data?.selfReportedDiabetic,
                      result?.data?.totalGraderReportCount,
                      result?.data?.graderAnyRefer,
                      result?.data?.graderDrRefer
                    ]
                  }
                ]
              : [
                  {
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
                  }
                ],
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
        if (typeof result?.data === "object") {
          this.showDetails(result?.data, false);
        }
      });
    // this.state.userResults.then(
    //   result => {
    //     this.setState({ isLoaded: true });
    //     if (result?.data?.roles?.includes("USER_ADMIN")) {
    //       this.resetList();
    //     }
    //   },
    //   () => {
    //     this.setState({ isLoaded: true });
    //   }
    // );
  }

  openCustomExportCSVModal() {
    this.setState({ showCustomExportCSVModal: true });
  }

  closeCustomExportCSVModal() {
    this.setState({ showCustomExportCSVModal: false });
  }

  showDetails(item, modal, total = false) {
    let seriesPie = [];
    seriesPie[0] = item?.leftEyeCounters?.noDR + item?.rightEyeCounters?.noDR;
    seriesPie[1] =
      item?.leftEyeCounters?.mildNpdr + item?.rightEyeCounters?.mildNpdr;
    seriesPie[2] =
      item?.leftEyeCounters?.modNpdr + item?.rightEyeCounters?.modNpdr;
    seriesPie[3] =
      item?.leftEyeCounters?.sevNpdr + item?.rightEyeCounters?.sevNpdr;
    seriesPie[4] = item?.leftEyeCounters?.pdr + item?.rightEyeCounters?.pdr;
    seriesPie[5] =
      item?.leftEyeCounters?.ungradable + item?.rightEyeCounters?.ungradable;
    // eslint-disable-next-line no-console
    console.log(item);
    if (
      (seriesPie[0] === 0 &&
        seriesPie[1] === 0 &&
        seriesPie[2] === 0 &&
        seriesPie[3] === 0 &&
        seriesPie[4] === 0 &&
        seriesPie[5] === 0) ||
      typeof item === "string"
    ) {
      this.setState({
        shouldDisplayPie: false,
        showModal: modal,
        totalDR: total
      });
    } else {
      // eslint-disable-next-line no-console
      console.log(seriesPie);
      this.setState({
        showModal: modal,
        seriesPie: seriesPie,
        shouldDisplayPie: true,
        totalDR: total
      });
    }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  goToPreviousPage() {
    getAuthenticatedData(backendBaseUrl + this.state.prevPage).then(
      result => {
        this.setState({
          statistics: Array.isArray(result?.data) ? result?.data : null,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
        this.showDetails(result?.data, false);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  goToNextPage() {
    getAuthenticatedData(backendBaseUrl + this.state.nextPage).then(
      result => {
        this.setState({
          statistics: Array.isArray(result?.data) ? result?.data : null,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
        this.showDetails(result?.data, false);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line no-console
    if (this.props.location.state !== prevProps.location.state) {
      this.setState({
        isLoaded: false,
        statistics: null,
        userResults: getAuthenticatedData(backendBaseUrl + getUser),
        prevPage: null,
        nextPage: null,
        shouldDisplayPie: false,
        showModal: false,
        api: getUserAnalytics,
        apiFull: getUserAnalytics,
        startDate: null,
        minDate: moment("2020-01-01"),
        maxDate: moment().endOf("month"),
        endDate: null,
        focusedInput: null,
        seriesPie: [0, 0, 0, 0, 0, 0],
        seriesBar: [
          {
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ],
        suggestions: [],
        searchParam: "",
        dateParam: ""
      });
      this.state.userResults
        .then(result => {
          if (this?.props?.location?.state?.type !== "personal") {
            this.setState({
              api: getAllSiteAnalytics,
              apiFull: getAllSiteAnalytics
            });
            getAuthenticatedData(backendBaseUrl + getTotalAnalytics).then(
              totals => {
                this.setState({ total: totals?.data });
              }
            );
            return Promise.resolve(
              getAuthenticatedData(backendBaseUrl + getAllSiteAnalytics)
            );
          } else {
            this.setState({
              api: getUserAnalytics,
              apiFull: getUserAnalytics + "/" + result?.data?.userId
            });
            return Promise.resolve(
              getAuthenticatedData(
                backendBaseUrl + getUserAnalytics + "/" + result?.data?.userId
              )
            );
          }
        })
        .then(result => {
          this.setState({
            statistics: Array.isArray(result?.data) ? result?.data : null,
            seriesBar: [
              {
                data: [
                  result?.data?.totalPatients,
                  result?.data?.totalExams,
                  result?.data?.totalPatientsImaged,
                  result?.data?.totalBSL,
                  result?.data?.bslOver140,
                  result?.data?.selfReportedDiabetic,
                  result?.data?.totalGraderReportCount,
                  result?.data?.graderAnyRefer,
                  result?.data?.graderDrRefer
                ]
              }
            ],
            isLoaded: true,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl
          });
          this.showDetails(result?.data, false);
        });
    }
  }

  handleClear() {
    this.setState({ searchParam: `` });
    getAuthenticatedData(
      backendBaseUrl + getAllSiteAnalytics + `?${this.state.dateParam}`
    ).then(result => {
      this.setState({
        statistics: result?.data,
        seriesBar: [
          {
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ],
        isLoaded: true,
        prevPage: result?.paging?.prevUrl,
        nextPage: result?.paging?.nextUrl
      });
    });
    getAuthenticatedData(
      backendBaseUrl + getTotalAnalytics + `?${this.state.dateParam}`
    ).then(totals => {
      this.setState({ total: totals?.data });
    });
  }

  handleSearch(value) {
    if (value) {
      this.setState({ searchParam: `search=${value}` });
      getAuthenticatedData(
        backendBaseUrl +
          getAllSiteAnalytics +
          `?${this.state.dateParam}${
            this.state.dateParam !== "" ? "&" : ""
          }search=${value}`
      ).then(result => {
        this.setState({
          statistics: result?.data,
          seriesBar: [
            {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          ],
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      });
      getAuthenticatedData(
        backendBaseUrl +
          getTotalAnalytics +
          `?${this.state.dateParam}${
            this.state.dateParam !== "" ? "&" : ""
          }search=${value}`
      ).then(totals => {
        this.setState({ total: totals?.data });
      });
    }
  }

  handleSearchChange() {}

  render() {
    const { isLoaded, statistics, total } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div className={this.state.showModal ? "prevent-scrolling" : ""}>
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar
                from={
                  this?.props?.location?.state?.type === "personal" ? "4" : "6"
                }
                results={this.state.userResults}
              />
            </div>
            <div className="col-md-10 rowmargin sidepadding">
              {(() => {
                if (this?.props?.location?.state?.type === "user") {
                  return (
                    <h6 className="exam-heading">
                      {this?.props?.location?.state?.userName} Statistics
                    </h6>
                  );
                } else if (this?.props?.location?.state?.name) {
                  return (
                    <h6 className="exam-heading">
                      {this?.props?.location?.state?.name} Statistics
                    </h6>
                  );
                } else if (this?.props?.location?.state?.type !== "personal") {
                  return (
                    <div className="row exam-heading-row">
                      <h6 className="exam-heading">Statistics</h6>
                      <Button
                        className="btn export"
                        onClick={this.openCustomExportCSVModal}
                      >
                        Export
                      </Button>
                    </div>
                  );
                }
                return <h6 className="exam-heading">Statistics</h6>;
              })()}
              <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="start_date" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="end_date" // PropTypes.string.isRequired,
                minDate={this.state.minDate}
                maxDate={this.state.maxDate}
                displayFormat={() => "DD/MM/YYYY"}
                minimumNights={0}
                isOutsideRange={day => {
                  return (
                    day.isBefore(moment("2020-01-01").startOf("day")) ||
                    day.isAfter(moment().endOf("day"))
                  );
                }}
                onDatesChange={({ startDate, endDate }) => {
                  this.setState({
                    startDate,
                    endDate
                  });
                  if (!startDate || !endDate) return;
                  if (!startDate.isValid() || !endDate.isValid()) return;
                  this.setState({
                    statistics: null,
                    dateParam:
                      "from=" +
                      startDate.valueOf() +
                      "&to=" +
                      endDate.valueOf(),
                    seriesBar: [
                      {
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
                      }
                    ]
                  });
                  if (this.state.api === getAllSiteAnalytics) {
                    getAuthenticatedData(
                      backendBaseUrl +
                        getTotalAnalytics +
                        "?from=" +
                        startDate.valueOf() +
                        "&to=" +
                        endDate.valueOf() +
                        (this.state.searchParam !== "" ? "&" : "") +
                        this.state.searchParam
                    ).then(result => {
                      this.setState({ total: result?.data });
                    });
                  }
                  getAuthenticatedData(
                    backendBaseUrl +
                      this.state.apiFull +
                      "?from=" +
                      startDate.valueOf() +
                      "&to=" +
                      endDate.valueOf() +
                      (this.state.searchParam !== "" ? "&" : "") +
                      this.state.searchParam
                  )
                    .then(result => {
                      // eslint-disable-next-line no-console
                      console.log(result);
                      this.setState({
                        statistics: Array.isArray(result?.data)
                          ? result?.data
                          : null,
                        seriesBar:
                          typeof result?.data === "object"
                            ? [
                                {
                                  data: [
                                    result?.data?.totalPatients,
                                    result?.data?.totalExams,
                                    result?.data?.totalPatientsImaged,
                                    result?.data?.totalBSL,
                                    result?.data?.bslOver140,
                                    result?.data?.selfReportedDiabetic,
                                    result?.data?.totalGraderReportCount,
                                    result?.data?.graderAnyRefer,
                                    result?.data?.graderDrRefer
                                  ]
                                }
                              ]
                            : [
                                {
                                  data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
                                }
                              ],
                        isLoaded: true,
                        prevPage: result?.paging?.prevUrl,
                        nextPage: result?.paging?.nextUrl
                      });
                      this.showDetails(result?.data);
                    })
                    .catch(result => {
                      this.setState({
                        statistics: Array.isArray(result?.data)
                          ? result?.data
                          : null,
                        seriesBar:
                          typeof result?.data === "object"
                            ? [
                                {
                                  data: [
                                    result?.data?.totalPatients,
                                    result?.data?.totalExams,
                                    result?.data?.totalPatientsImaged,
                                    result?.data?.totalBSL,
                                    result?.data?.bslOver140,
                                    result?.data?.selfReportedDiabetic,
                                    result?.data?.totalGraderReportCount,
                                    result?.data?.graderAnyRefer,
                                    result?.data?.graderDrRefer
                                  ]
                                }
                              ]
                            : [
                                {
                                  data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
                                }
                              ],
                        isLoaded: true,
                        prevPage: result?.paging?.prevUrl,
                        nextPage: result?.paging?.nextUrl
                      });
                      this.showDetails(result?.data);
                    });
                }} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => {
                  this.setState({ focusedInput });
                }} // PropTypes.func.isRequired,
              />
              {(() => {
                if (statistics) {
                  return (
                    <SearchBar
                      autoFocus
                      shouldRenderClearButton
                      shouldRenderSearchButton
                      renderClearButton
                      renderSearchButton
                      placeholder="Search Statistics"
                      onChange={this.handleSearchChange}
                      onClear={this.handleClear}
                      onSearch={this.handleSearch}
                      suggestions={this.state.suggestions}
                      styles={styles}
                    />
                  );
                }
              })()}
              {(() => {
                if (statistics && statistics.length === 0) {
                  return (
                    <>
                      <div className="table-error">
                        <p>There are no results to display</p>
                      </div>
                    </>
                  );
                } else if (statistics) {
                  return (
                    <>
                      <h6 className="total-heading">Total Statistics</h6>
                      <table className="total-table">
                        <tr className="headings">
                          <th>Total Patients</th>
                          <th>Total Exams</th>
                          <th>Total Exams with Images</th>
                          <th>Total BSL</th>
                          <th>BSL Over 140</th>
                          <th>Self Reported Diabetic</th>
                          <th>Total Exams Graded</th>
                          <th>Grader Any Refer</th>
                          <th>Grader DR Refer</th>
                          <th>Queue Size</th>
                          <td rowSpan="2" className="total-dr">
                            <Button
                              className="addaccount dr-stats-btn"
                              onClick={() =>
                                this.showDetails(total, true, true)
                              }
                            >
                              DR Stats
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td className="site-totals">
                            {total?.totalPatients}
                          </td>
                          <td className="site-totals">{total?.totalExams}</td>
                          <td className="site-totals">
                            {total?.totalPatientsImaged}
                          </td>
                          <td className="site-totals">{total?.totalBSL}</td>
                          <td className="site-totals">{total?.bslOver140}</td>
                          <td className="site-totals">
                            {total?.selfReportedDiabetic}
                          </td>
                          <td className="site-totals">
                            {total?.totalGraderReportCount}
                          </td>
                          <td className="site-totals">
                            {total?.graderAnyRefer}
                          </td>
                          <td className="site-totals">
                            {total?.graderDrRefer}
                          </td>
                          <td className="site-totals">{total?.queueSize}</td>
                        </tr>
                      </table>
                      <h6>Site Statistics</h6>
                      <div className="table-container rowmargin">
                        <Table className="tablebox">
                          <thead>
                            <tr>
                              <th>Site Name</th>
                              <th>Total Patients</th>
                              <th>Total Exams</th>
                              <th>Total Exams with Images</th>
                              <th>Total BSL</th>
                              <th>BSL Over 140</th>
                              <th>Self Reported Diabetic</th>
                              <th>Total Exams Graded</th>
                              <th>Grader Any Refer</th>
                              <th>Grader DR Refer</th>
                              <th>Queue Size</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {statistics?.map?.(item => (
                              <tr key={item?.siteId}>
                                {(() => {
                                  if (this.state.api === getUserAnalytics)
                                    return null;
                                  else return <td>{item?.siteName}</td>;
                                })()}
                                <td>{item?.totalPatients}</td>
                                <td>{item?.totalExams}</td>
                                <td>{item?.totalPatientsImaged}</td>
                                <td>{item?.totalBSL}</td>
                                <td>{item?.bslOver140}</td>
                                <td>{item?.selfReportedDiabetic}</td>
                                <td>{item?.totalGraderReportCount}</td>
                                <td>{item?.graderAnyRefer}</td>
                                <td>{item?.graderDrRefer}</td>
                                <td>{item?.queueSize}</td>
                                <td>
                                  <a
                                    id={item?.siteId}
                                    className="show-eye-statistics"
                                    onClick={() => this.showDetails(item, true)}
                                  >
                                    Show Detailed DR Statistics
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="custom-pagination">
                          {(() => {
                            if (
                              this.state.prevPage === null ||
                              !this.state.prevPage
                            ) {
                              return <td></td>;
                            }
                            return (
                              <div
                                className="pagination-previous"
                                onClick={() => this.goToPreviousPage()}
                              >
                                <span>&lt; Previous</span>
                              </div>
                            );
                          })()}
                          {(() => {
                            if (
                              this.state.nextPage === null ||
                              !this.state.nextPage
                            ) {
                              return <td></td>;
                            }
                            return (
                              <div
                                className="pagination-next"
                                onClick={() => this.goToNextPage()}
                              >
                                <span>Next &gt;</span>
                              </div>
                            );
                          })()}
                        </div>
                      </div>
                    </>
                  );
                } else {
                  return (
                    <ReactApexChart
                      options={this.state.optionsBar}
                      series={this.state.seriesBar}
                      type="bar"
                      height={350}
                    />
                  );
                }
              })()}
              {(() => {
                if (
                  this.state.shouldDisplayPie &&
                  this.state.api === getUserAnalytics
                ) {
                  return (
                    <div className="pie-chart-container">
                      <ReactApexChart
                        options={this.state.optionsPie}
                        series={this.state.seriesPie}
                        type="donut"
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })()}
            </div>
          </div>
          {this.state.showModal && (
            <div className="custommodal">
              <div className="custommodalinner">
                <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                  {(() => {
                    if (this.state.totalDR) {
                      return (
                        <h6 className="modal-heading">Total DR Statistics</h6>
                      );
                    }
                    return (
                      <h6 className="modal-heading">Detailed DR Statistics</h6>
                    );
                  })()}
                  <Button
                    // eslint-disable-next-line react/prop-types
                    onClick={this.closeModal}
                    className="cancle"
                  >
                    {" "}
                    X{" "}
                  </Button>
                  <hr className="hrpadding" />
                  {(() => {
                    if (this.state.shouldDisplayPie) {
                      return (
                        <ReactApexChart
                          options={this.state.optionsPie}
                          series={this.state.seriesPie}
                          type="donut"
                        />
                      );
                    } else {
                      return (
                        <div className="table-error">
                          <p>There is not enough data to display a graph.</p>
                        </div>
                      );
                    }
                  })()}
                </form>
              </div>
            </div>
          )}
          {this.state.showCustomExportCSVModal ? (
            <CustomExportCSVModal
              endpoint={analyticsExportToCSV}
              closePopup={this.closeCustomExportCSVModal}
            />
          ) : null}
        </div>
      );
    }
  }
}
