/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdmin: false,
      bearerToken: "",
      firstName: "",
      lastName: "",
      redirect: false,
      siteId: 0
    };
    this.logout = this.logout.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    let promise;
    promise = this.props.results;
    promise.then(result => {
      if (result?.data?.roles?.includes("USER_ADMIN")) {
        this.setState({ showAdmin: true });
      }
      this.setState({
        firstName: result?.data?.firstName,
        lastName: result?.data?.lastName,
        siteId: result?.data?.siteId,
        siteName: result?.data?.siteName
      });
    });
  }

  logout() {
    if (sessionStorage.getItem("bearer")) {
      sessionStorage.removeItem("bearer");
    }
    this.setState({ redirect: true });
  }

  render() {
    let adminTemplate,
      mySiteStatisticsTemplate,
      statisticsTemplate,
      sitesTemplate = null;
    if (this.state.showAdmin) {
      adminTemplate = (
        <Link to="/">
          <div
            className={
              "adminoption" + (this?.props?.from === "0" ? " selected" : "")
            }
          >
            Users
          </div>
        </Link>
      );
    }
    if (this.state.showAdmin) {
      statisticsTemplate = (
        <Link to="/statistics">
          <div
            className={
              "adminoption" + (this?.props?.from === "6" ? " selected" : "")
            }
          >
            All Site Statistics
          </div>
        </Link>
      );
    }
    if (this.state.showAdmin) {
      sitesTemplate = (
        <Link to="/sites">
          <div
            className={
              "adminoption" + (this?.props?.from === "2" ? " selected" : "")
            }
          >
            Sites
          </div>
        </Link>
      );
    }
    if (!this.state.showAdmin) {
      mySiteStatisticsTemplate = (
        <Link
          to={{
            pathname: "/site-statistics",
            state: {
              id: this.state.siteId,
              from: "sidebar",
              name: this.state.siteName
            }
          }}
        >
          <div
            className={
              "adminoption" + (this?.props?.from === "5" ? " selected" : "")
            }
          >
            {this.state.siteName} Statistics
          </div>
        </Link>
      );
    }
    if (this?.state?.redirect) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div>
        <div className="marginside sidebar-name">
          <div className="adminname">
            {this?.state?.firstName} {this?.state?.lastName}
          </div>
        </div>
        <hr />
        <div className="marginside">
          {adminTemplate}
          {sitesTemplate}
          <Link to="/exam-data">
            <div
              className={
                "adminoption" + (this?.props?.from === "3" ? " selected" : "")
              }
            >
              My Exam Data
            </div>
          </Link>
          {statisticsTemplate}
          {mySiteStatisticsTemplate}
          <Link
            to={{
              pathname: "/statistics",
              state: {
                type: "personal"
              }
            }}
          >
            <div
              className={
                "adminoption" + (this?.props?.from === "4" ? " selected" : "")
              }
            >
              My Personal Statistics
            </div>
          </Link>
          <Link to="/edit-admin">
            <div
              className={
                "adminoption" + (this?.props?.from === "1" ? " selected" : "")
              }
            >
              Edit Password
            </div>
          </Link>

          <div onClick={this.logout} className="adminoption logout">
            Logout
          </div>
        </div>
      </div>
    );
  }
}
