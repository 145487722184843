/* eslint-disable react/prop-types */
import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table
} from "reactstrap";
import { Button } from "react-bootstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomModal from "./CustomModal";
import { backendBaseUrl } from "../../config";
import {
  getAllExamsForType,
  getUser,
  getAIReportLink,
  getPdfLink,
  getImages,
  examExportToCSV
} from "../../constants/endpoints";
import SearchBar from "../SearchBar";
import { convertEpochToDate } from "../../utils/date";
import { getAuthenticatedData } from "../../utils/async";
import FsLightbox from "fslightbox-react";
import styles from "../SearchBar/search.module.css";
import CustomExportCSVModal from "./CustomExportCSVModal";
// import { toast } from "react-toastify";

export default class ExamData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      exams: [],
      userResults: getAuthenticatedData(backendBaseUrl + getUser),
      prevPage: null,
      nextPage: null,
      toggler: false,
      images: [],
      imageThumbs: [],
      examId: 0,
      examDates: [],
      gradedDates: [],
      myExam: false,
      fromText: "",
      suggestions: [],
      type: "",
      queryObjectId: "",
      sortDropdownOpen: false,
      searchValue: "",
      sortBy: 0,
      showCustomExportCSVModal: false
    };
    this.viewReport = this.viewReport.bind(this);
    this.viewAIReport = this.viewAIReport.bind(this);
    this.viewImages = this.viewImages.bind(this);
    this.goToPreviousPage = this.goToPreviousPage.bind(this);
    this.goToNextPage = this.goToNextPage.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sortDropdownToggle = this.sortDropdownToggle.bind(this);
    this.sortTable = this.sortTable.bind(this);
    this.openCustomExportCSVModal = this.openCustomExportCSVModal.bind(this);
    this.closeCustomExportCSVModal = this.closeCustomExportCSVModal.bind(this);
  }

  componentDidMount() {
    this.state.userResults.then(
      result => {
        let type, id;
        if (!this?.props?.location?.state?.from) {
          this.setState({ fromText: "My Exam Data" });
        } else {
          this.setState({
            fromText: `Exam Data for ${this?.props?.location?.state?.from}`
          });
        }
        if (
          !this?.props?.location?.state?.type ||
          !this?.props?.location?.state?.id
        ) {
          this.setState({ myExam: true });
          type = "user";
          id = result?.data?.userId;
        } else {
          type = this?.props?.location?.state?.type;
          id = this?.props?.location?.state?.id;
        }
        this.setState({
          type,
          queryObjectId: id
        });
        getAuthenticatedData(
          backendBaseUrl +
            getAllExamsForType +
            `?type=${type}&queryObjectId=${id}`
        ).then(
          result => {
            this.setState({
              exams: result?.data,
              isLoaded: true,
              prevPage: result?.paging?.prevUrl,
              nextPage: result?.paging?.nextUrl
            });
            this.getDates(result?.data);
            this.getGradedDates(result?.data);
          },
          () => {
            this.setState({
              isLoaded: true
            });
          }
        );
      },
      () => {
        this.setState({ isLoaded: true });
      }
    );
  }

  openCustomExportCSVModal() {
    this.setState({ showCustomExportCSVModal: true });
  }

  closeCustomExportCSVModal() {
    this.setState({ showCustomExportCSVModal: false });
  }

  getDates(items) {
    let examDates = items?.map?.(item =>
      convertEpochToDate(item?.examDetails?.examDate)
    );
    this.setState({ examDates });
  }

  getGradedDates(items) {
    let gradedDates = items?.map?.(item => {
      if (item?.examDetails?.gradedDate === 0) return "-";
      return convertEpochToDate(item?.examDetails?.gradedDate);
    });
    this.setState({ gradedDates });
  }

  viewReport(item) {
    getAuthenticatedData(
      backendBaseUrl + getPdfLink + `/${item.examDetails.id}`
    ).then(
      result => {
        window.open(result.data, "_blank");
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  viewAIReport(item) {
    getAuthenticatedData(
      backendBaseUrl + getAIReportLink + `/${item.examDetails.id}`
    ).then(
      result => {
        window.open(result.data, "_blank");
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  viewImages(item) {
    if (item.hasImages) {
      getAuthenticatedData(
        backendBaseUrl + getImages + `/${item.examDetails.id}`
      ).then(
        result => {
          this.setState({
            images: result?.data?.map(item => item?.path),
            imageThumbs: result?.data?.map(item => item?.thumbnailPath),
            examId: item.examDetails.id
          });
          this.setState(state => {
            return { toggler: !state.toggler };
          });
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
  }

  goToPreviousPage() {
    getAuthenticatedData(backendBaseUrl + this.state.prevPage).then(
      result => {
        this.setState({
          exams: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
        this.getDates(result?.data);
        this.getGradedDates(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  goToNextPage() {
    getAuthenticatedData(backendBaseUrl + this.state.nextPage).then(
      result => {
        this.setState({
          exams: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
        this.getDates(result?.data);
        this.getGradedDates(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  handleClear() {
    getAuthenticatedData(
      backendBaseUrl +
        getAllExamsForType +
        `?type=${this.state.type}&queryObjectId=${
          this.state.queryObjectId
        }&sortBy=${this.state.sortBy === 0 ? "created" : "graded"}`
    ).then(
      result => {
        this.setState({
          exams: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          searchValue: ""
        });
        this.getDates(result?.data);
        this.getGradedDates(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  handleSearch(value) {
    if (value) {
      // eslint-disable-next-line no-console
      getAuthenticatedData(
        backendBaseUrl +
          getAllExamsForType +
          `?type=${this.state.type}&queryObjectId=${
            this.state.queryObjectId
          }&search=${value}&sortType=${
            this.state.sortBy === 0 ? "created" : "graded"
          }`
      ).then(
        result => {
          this.setState({
            exams: result?.data,
            isLoaded: true,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl,
            searchValue: value
          });
          this.getDates(result?.data);
          this.getGradedDates(result?.data);
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
  }

  sortDropdownToggle() {
    this.setState({ sortDropdownOpen: !this.state.sortDropdownOpen });
  }

  sortTable(sortBy) {
    getAuthenticatedData(
      backendBaseUrl +
        getAllExamsForType +
        `?type=${this.state.type}&queryObjectId=${
          this.state.queryObjectId
        }&sortType=${sortBy === 0 ? "created" : "graded"}${
          this.state.searchValue === ""
            ? ""
            : "&search=" + this.state.searchValue
        }`
    ).then(
      result => {
        this.setState({
          exams: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl,
          sortBy: sortBy
        });
        this.getDates(result?.data);
        this.getGradedDates(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  handleChange() {}

  render() {
    const {
      isLoaded,
      exams,
      toggler,
      images,
      imageThumbs,
      examId,
      examDates,
      gradedDates,
      fromText,
      sortDropdownOpen
    } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar
                from={this.state.myExam ? "3" : null}
                results={this.state.userResults}
              />
            </div>
            <div className="col-md-10 sidepadding">
              <div className="row rowmargin abovetable">
                <div className="addaccount-container">
                  {this.state.showPopup ? (
                    <CustomModal
                      text='Click "Close Button" to hide popup'
                      closePopup={this.togglePopup}
                      resetList={this.resetList}
                    />
                  ) : null}
                </div>
              </div>
              <div className="row exam-heading-row">
                <h6 className="exam-heading">{fromText}</h6>
                <Button
                  className="btn export"
                  onClick={this.openCustomExportCSVModal}
                >
                  Export
                </Button>
              </div>
              <div className="exam-options">
                <div className="search-container">
                  <SearchBar
                    autoFocus
                    shouldRenderClearButton
                    shouldRenderSearchButton
                    renderClearButton
                    renderSearchButton
                    placeholder="Search Exam Data"
                    onChange={this.handleChange}
                    onClear={this.handleClear}
                    onSearch={this.handleSearch}
                    suggestions={this.state.suggestions}
                    styles={styles}
                  />
                </div>
                <div className="sort-container">
                  <Dropdown
                    group
                    size="sm"
                    isOpen={sortDropdownOpen}
                    toggle={this.sortDropdownToggle}
                  >
                    <DropdownToggle color="secondary" caret>
                      Sort By
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={() => this.sortTable(0)}>
                        Exam Date
                      </DropdownItem>
                      <DropdownItem onClick={() => this.sortTable(1)}>
                        Graded Date
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              {(() => {
                if (!exams || exams.length === 0 || typeof exams !== "object") {
                  return (
                    <div className="table-error">
                      <p>There are no results to display</p>
                    </div>
                  );
                } else {
                  return (
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Gender</th>
                            <th>MRN Number</th>
                            <th>Site</th>
                            <th>Grader</th>
                            <th>Exam Status</th>
                            <th>Exam Date</th>
                            <th>Graded Date</th>
                            <th>Refer Required</th>
                            <th>BSL</th>
                            <th>AI Result</th>
                            <th>Report</th>
                            <th>AI Report</th>
                            <th>Images</th>
                          </tr>
                        </thead>
                        <tbody>
                          {exams?.map?.((item, index) => (
                            <tr key={item.examDetails.id}>
                              <td>
                                {item.patientDetails.firstName}{" "}
                                {item.patientDetails.lastName}
                              </td>
                              <td className="patient-gender">
                                {item.patientDetails.gender}
                              </td>
                              <td>{item.patientDetails.mrn}</td>
                              {(() => {
                                if (!item.site) {
                                  return <td>-</td>;
                                } else {
                                  return <td>{item.site.siteName}</td>;
                                }
                              })()}
                              {(() => {
                                if (!item.grader) {
                                  return <td>-</td>;
                                } else {
                                  return (
                                    <td>
                                      {item.grader.firstName}{" "}
                                      {item.grader.lastName}
                                    </td>
                                  );
                                }
                              })()}
                              <td className="grader-name">
                                {item.examDetails.examStatus}
                              </td>
                              <td>{examDates[index]}</td>
                              <td>{gradedDates[index]}</td>
                              {(() => {
                                if (!item.report) {
                                  return <td>-</td>;
                                }
                                if (item.report.referRequired) {
                                  return <td>Yes</td>;
                                } else {
                                  return <td>No</td>;
                                }
                              })()}
                              <td>{item.examDetails.bloodSugarLevel}</td>
                              {(() => {
                                if (!item?.aiReport) {
                                  return <td>-</td>;
                                }
                                if (
                                  item?.aiReport.suggestedRefer &&
                                  (!item?.aiReport.inputSufficient ||
                                    !item?.aiReport.qualitySufficient)
                                ) {
                                  return <td>DR*</td>;
                                }
                                if (
                                  item?.aiReport.suggestedRefer &&
                                  (item?.aiReport.inputSufficient &&
                                    item?.aiReport.qualitySufficient)
                                ) {
                                  return <td>DR</td>;
                                }
                                if (
                                  !item?.aiReport.suggestedRefer &&
                                  (!item?.aiReport.inputSufficient ||
                                    !item?.aiReport.qualitySufficient)
                                ) {
                                  return <td>No DR*</td>;
                                }
                                if (
                                  !item?.aiReport.suggestedRefer &&
                                  (item?.aiReport.inputSufficient &&
                                    item?.aiReport.qualitySufficient)
                                ) {
                                  return <td>No DR</td>;
                                }
                              })()}
                              {(() => {
                                if (item.report === null) {
                                  return <td>-</td>;
                                } else {
                                  return (
                                    <td className="tablemodal">
                                      <a
                                        id={item.userId}
                                        className="editbtn"
                                        onClick={() => this.viewReport(item)}
                                      >
                                        View Report
                                      </a>
                                    </td>
                                  );
                                }
                              })()}
                              {(() => {
                                if (item.aiReport === null) {
                                  return <td>-</td>;
                                } else {
                                  return (
                                    <td className="tablemodal">
                                      <a
                                        id={item.userId}
                                        className="editbtn"
                                        onClick={() => this.viewAIReport(item)}
                                      >
                                        View AI Report
                                      </a>
                                    </td>
                                  );
                                }
                              })()}
                              {(() => {
                                if (item.hasImages) {
                                  return (
                                    <td className="tablemodal">
                                      <a
                                        id={item.userId}
                                        className="editbtn"
                                        onClick={() => this.viewImages(item)}
                                      >
                                        View Images
                                      </a>
                                    </td>
                                  );
                                } else {
                                  return <td>-</td>;
                                }
                              })()}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="custom-pagination">
                        {(() => {
                          if (
                            this.state.prevPage === null ||
                            !this.state.prevPage
                          ) {
                            return <span></span>;
                          }
                          return (
                            <div
                              className="pagination-previous"
                              onClick={() => this.goToPreviousPage()}
                            >
                              <span>&lt; Previous</span>
                            </div>
                          );
                        })()}
                        {(() => {
                          if (
                            this.state.nextPage === null ||
                            !this.state.nextPage
                          ) {
                            return <span></span>;
                          }
                          return (
                            <div
                              className="pagination-next"
                              onClick={() => this.goToNextPage()}
                            >
                              <span>Next &gt;</span>
                            </div>
                          );
                        })()}
                      </div>
                      <p className="disclaimer">
                        *Data insufficient for results to be conclusive
                      </p>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
          <FsLightbox
            toggler={toggler}
            sources={images}
            thumbs={imageThumbs}
            type="image"
            key={examId}
            showThumbsOnMount={true}
          />
          {this.state.showCustomExportCSVModal ? (
            <CustomExportCSVModal
              endpoint={examExportToCSV}
              queryObjectId={this.state.queryObjectId}
              type={this.state.type}
              closePopup={this.closeCustomExportCSVModal}
            />
          ) : null}
        </div>
      );
    }
  }
}
