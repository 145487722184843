import {
  commonAuthenticatedHeaders,
  commonUnauthenticatedHeaders
} from "../constants/headers";
import history from "./history";
import { toast } from "react-toastify";

export function getAuthenticatedData(url = "") {
  return fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      ...commonAuthenticatedHeaders()
    }
  })
    .catch(e => {
      toast.error("Something went wrong!");
      throw e;
    })
    .then(response => {
      if (response.status === 401) {
        if (sessionStorage.getItem("bearer")) {
          sessionStorage.removeItem("bearer");
        }
        history.push("/login");
      }
      return response.json();
    })
    .then(response => {
      return response;
    });
}

export function deleteAuthenticatedData(url = "", data = {}) {
  return fetch(url, {
    method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      ...commonAuthenticatedHeaders()
    },
    body: JSON.stringify(data)
  })
    .catch(e => {
      toast.error("Something went wrong!");
      throw e;
    })
    .then(response => {
      if (response.status === 401) {
        if (sessionStorage.getItem("bearer")) {
          sessionStorage.removeItem("bearer");
        }
        history.push("/login");
      }
      return response;
    });
}

export function getUnauthenticatedData(url = "") {
  return fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      ...commonUnauthenticatedHeaders()
    }
  })
    .catch(e => {
      toast.error("Something went wrong!");
      throw e;
    })
    .then(response => {
      return response;
    });
}

export function postAuthenticatedData(url = "", data = {}) {
  return fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      ...commonAuthenticatedHeaders()
    },
    body: JSON.stringify(data)
  })
    .catch(e => {
      toast.error("Something went wrong!");
      throw e;
    })
    .then(response => {
      if (response.status === 401) {
        if (sessionStorage.getItem("bearer")) {
          sessionStorage.removeItem("bearer");
        }
        history.push("/login");
      }
      return response;
    });
}

export function putAuthenticatedData(url = "", data = {}) {
  return fetch(url, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      ...commonAuthenticatedHeaders()
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
    .catch(e => {
      toast.error("Something went wrong!");
      throw e;
    })
    .then(response => {
      if (response.status === 401) {
        if (sessionStorage.getItem("bearer")) {
          sessionStorage.removeItem("bearer");
        }
        history.push("/login");
      }
      return response;
    });
}

export function putUnauthenticatedData(url = "", data = {}, extraHeaders = {}) {
  return fetch(url, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      ...commonUnauthenticatedHeaders(),
      ...extraHeaders
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
    .catch(e => {
      toast.error("Something went wrong!");
      throw e;
    })
    .then(response => {
      return response;
    });
}

export function postUnauthenticatedData(url = "", data = {}) {
  return fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      ...commonUnauthenticatedHeaders()
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
    .catch(e => {
      toast.error("Something went wrong!");
      throw e;
    })
    .then(response => {
      return response;
    });
}
