import React from "react";
import "react-dates/initialize";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import { backendBaseUrl } from "../../config";
import { getUser, getSiteAnalytics } from "../../constants/endpoints";
import { getAuthenticatedData } from "../../utils/async";
import ReactApexChart from "react-apexcharts";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
// import { toast } from "react-toastify";

export default class SiteStatistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      userResults: getAuthenticatedData(backendBaseUrl + getUser),
      prevPage: null,
      nextPage: null,
      isAdmin: false,
      shouldDisplayPie: false,
      startDate: null,
      minDate: moment("2020-01-01"),
      maxDate: moment().endOf("month"),
      endDate: null,
      focusedInput: null,
      seriesPie: [0, 0, 0, 0, 0, 0],
      optionsPie: {
        chart: {
          type: "donut"
        },
        labels: [
          "No DR",
          "Mild NPDR",
          "Moderate NPDR",
          "Severe NPDR",
          "PDR",
          "Ungradeable"
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
      optionsBar: {
        chart: {
          type: "bar",
          toolbar: {
            show: false
          },
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        tooltip: {
          theme: "dark",
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function() {
                return "";
              }
            }
          }
        },
        colors: ["#33b2df"],
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [
            "Total Patients",
            "Total Exams",
            "Total Exams with Images",
            "Total BSL",
            "BSL over 140",
            "Self Reported Diabetic",
            "Total Exams Graded",
            "Grader Any Refer",
            "Grader DR Refer",
            "Queue Size"
          ]
        }
      },
      seriesBar: [
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ]
    };
  }

  componentDidMount() {
    getAuthenticatedData(
      backendBaseUrl + getSiteAnalytics + "/" + this?.props?.location?.state?.id
    ).then(result => {
      this.setState({
        seriesBar:
          typeof result?.data === "object"
            ? [
                {
                  data: [
                    result?.data?.totalPatients,
                    result?.data?.totalExams,
                    result?.data?.totalPatientsImaged,
                    result?.data?.totalBSL,
                    result?.data?.bslOver140,
                    result?.data?.selfReportedDiabetic,
                    result?.data?.totalGraderReportCount,
                    result?.data?.graderAnyRefer,
                    result?.data?.graderDrRefer,
                    result?.data?.queueSize
                  ]
                }
              ]
            : [
                {
                  data: [0, 0, 0, 0, 0, 0, 0, 0]
                }
              ],
        isLoaded: true,
        prevPage: result?.paging?.prevUrl,
        nextPage: result?.paging?.nextUrl
      });
      this.showDetails(result?.data);
    });
    // this.state.userResults.then(
    //   result => {
    //     this.setState({ isLoaded: true });
    //     if (result?.data?.roles?.includes("USER_ADMIN")) {
    //       this.resetList();
    //     }
    //   },
    //   () => {
    //     this.setState({ isLoaded: true });
    //   }
    // );
  }

  showDetails(item) {
    let seriesPie = [];
    seriesPie[0] = item?.leftEyeCounters?.noDR + item?.rightEyeCounters?.noDR;
    seriesPie[1] =
      item?.leftEyeCounters?.mildNpdr + item?.rightEyeCounters?.mildNpdr;
    seriesPie[2] =
      item?.leftEyeCounters?.modNpdr + item?.rightEyeCounters?.modNpdr;
    seriesPie[3] =
      item?.leftEyeCounters?.sevNpdr + item?.rightEyeCounters?.sevNpdr;
    seriesPie[4] = item?.leftEyeCounters?.pdr + item?.rightEyeCounters?.pdr;
    seriesPie[5] =
      item?.leftEyeCounters?.ungradable + item?.rightEyeCounters?.ungradable;
    // eslint-disable-next-line no-console
    console.log(item);
    if (
      (seriesPie[0] === 0 &&
        seriesPie[1] === 0 &&
        seriesPie[2] === 0 &&
        seriesPie[3] === 0 &&
        seriesPie[4] === 0 &&
        seriesPie[5] === 0) ||
      typeof item === "string"
    ) {
      this.setState({ shouldDisplayPie: false });
    } else {
      // eslint-disable-next-line no-console
      console.log(seriesPie);
      this.setState({
        seriesPie: typeof item !== "object" ? [0, 0, 0, 0, 0, 0] : seriesPie,
        shouldDisplayPie: typeof item === "object"
      });
    }
  }

  goToPreviousPage() {
    getAuthenticatedData(backendBaseUrl + this.state.prevPage).then(
      result => {
        this.showDetails(result?.data);
        this.setState({
          seriesBar: [
            {
              data: [
                result?.data?.totalPatients,
                result?.data?.totalExams,
                result?.data?.totalPatientsImaged,
                result?.data?.totalBSL,
                result?.data?.bslOver140,
                result?.data?.selfReportedDiabetic,
                result?.data?.totalGraderReportCount,
                result?.data?.graderAnyRefer,
                result?.data?.graderDrRefer,
                result?.data?.queueSize
              ]
            }
          ],
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  goToNextPage() {
    getAuthenticatedData(backendBaseUrl + this.state.nextPage).then(
      result => {
        this.showDetails(result?.data);
        this.setState({
          seriesBar: [
            {
              data: [
                result?.data?.totalPatients,
                result?.data?.totalExams,
                result?.data?.totalPatientsImaged,
                result?.data?.totalBSL,
                result?.data?.bslOver140,
                result?.data?.selfReportedDiabetic,
                result?.data?.totalGraderReportCount,
                result?.data?.graderAnyRefer,
                result?.data?.graderDrRefer,
                result?.data?.queueSize
              ]
            }
          ],
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  render() {
    const { isLoaded } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div>
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from="5" results={this.state.userResults} />
            </div>
            <div className="col-md-10 rowmargin sidepadding">
              {(() => {
                return (
                  <h6 className="exam-heading">
                    {this?.props?.location?.state?.name} Statistics
                  </h6>
                );
              })()}
              <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="start_date" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="end_date" // PropTypes.string.isRequired,
                minDate={this.state.minDate}
                maxDate={this.state.maxDate}
                displayFormat={() => "DD/MM/YYYY"}
                minimumNights={0}
                isOutsideRange={day => {
                  return (
                    day.isBefore(moment("2020-01-01").startOf("day")) ||
                    day.isAfter(moment().endOf("day"))
                  );
                }}
                onDatesChange={({ startDate, endDate }) => {
                  this.setState({ startDate, endDate });
                  if (!startDate || !endDate) return;
                  if (!startDate.isValid() || !endDate.isValid()) return;
                  getAuthenticatedData(
                    backendBaseUrl +
                      getSiteAnalytics +
                      "/" +
                      this?.props?.location?.state?.id +
                      "?from=" +
                      startDate.valueOf() +
                      "&to=" +
                      endDate.valueOf()
                  )
                    .then(result => {
                      this.setState({
                        statistics: result?.data,
                        seriesBar:
                          typeof result?.data === "object"
                            ? [
                                {
                                  data: [
                                    result?.data?.totalPatients,
                                    result?.data?.totalExams,
                                    result?.data?.totalPatientsImaged,
                                    result?.data?.totalBSL,
                                    result?.data?.bslOver140,
                                    result?.data?.selfReportedDiabetic,
                                    result?.data?.totalGraderReportCount,
                                    result?.data?.graderAnyRefer,
                                    result?.data?.graderDrRefer,
                                    result?.data?.queueSize
                                  ]
                                }
                              ]
                            : [
                                {
                                  data: [0, 0, 0, 0, 0, 0, 0, 0]
                                }
                              ],
                        isLoaded: true,
                        prevPage: result?.paging?.prevUrl,
                        nextPage: result?.paging?.nextUrl
                      });
                      this.showDetails(result?.data);
                    })
                    .catch(result => {
                      this.setState({
                        statistics: result?.data,
                        isLoaded: true,
                        seriesBar:
                          typeof result?.data === "object"
                            ? [
                                {
                                  data: [
                                    result?.data?.totalPatients,
                                    result?.data?.totalExams,
                                    result?.data?.totalPatientsImaged,
                                    result?.data?.totalBSL,
                                    result?.data?.bslOver140,
                                    result?.data?.selfReportedDiabetic,
                                    result?.data?.totalGraderReportCount,
                                    result?.data?.graderAnyRefer,
                                    result?.data?.graderDrRefer,
                                    result?.data?.queueSize
                                  ]
                                }
                              ]
                            : [
                                {
                                  data: [0, 0, 0, 0, 0, 0, 0, 0]
                                }
                              ],
                        prevPage: result?.paging?.prevUrl,
                        nextPage: result?.paging?.nextUrl
                      });
                      this.showDetails(result?.data);
                    });
                }} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => {
                  this.setState({ focusedInput });
                }} // PropTypes.func.isRequired,
              />
              {(() => {
                return (
                  <div className="table-container rowmargin">
                    <ReactApexChart
                      options={this.state.optionsBar}
                      series={this.state.seriesBar}
                      type="bar"
                      height={350}
                    />
                    {(() => {
                      if (this.state.shouldDisplayPie) {
                        return (
                          <div className="pie-chart-container">
                            <ReactApexChart
                              options={this.state.optionsPie}
                              series={this.state.seriesPie}
                              type="donut"
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })()}
                  </div>
                );
              })()}
            </div>
          </div>
        </div>
      );
    }
  }
}
