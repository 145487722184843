import React, { Component } from "react";
import {
  Nav,
  Navbar
  // NavItem,
  // NavLink
} from "reactstrap";
import RemidioLogo from "../../assets/images/remidio@3x.png";
import VFILogo from "../../assets/images/vfiLogo.png";

export default class AppNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.state = { showPopup: false };
    this.togglePopup = this.togglePopup.bind(this);
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  logout() {
    window.location.href = "/";
  }
  render() {
    return (
      <Navbar color="#ffffff" expand="md">
        <Nav className="logo-container col-md-12" navbar>
          <img
            className="logos remidio-logo"
            src={RemidioLogo}
            alt="Remidio Logo"
          />
          <img className="logos vfi-logo" src={VFILogo} alt="VFI Logo" />
        </Nav>
      </Navbar>
    );
  }
}
