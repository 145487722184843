/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup } from "react-bootstrap";
// import { toast } from "react-toastify";
import { postAuthenticatedData } from "../../utils/async";

import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { backendBaseUrl } from "../../config";
import { toast } from "react-toastify";

/**
 * This is the component for the Data Sync modal
 *
 * @component
 */
class CustomExportCSVModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      minDate: moment("2020-01-01"),
      maxDate: moment().endOf("month"),
      focusedInput: null,
      disableButton: true,
      isLoaded: true
    };

    this.onSubmit = this.onSubmit.bind(this);
    // this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {}

  onSubmit() {
    let url;
    this.setState({ disableButton: true, isLoaded: false });
    if (!this.props.queryObjectId || !this.props.type) {
      url =
        backendBaseUrl +
        this.props.endpoint +
        `?from=${this.state.startDate.valueOf()}&to=${this.state.endDate.valueOf()}`;
    } else {
      url =
        backendBaseUrl +
        this.props.endpoint +
        `?queryObjectId=${this.props.queryObjectId}&type=${
          this.props.type
        }&from=${this.state.startDate.valueOf()}&to=${this.state.endDate.valueOf()}`;
    }
    postAuthenticatedData(url).then(result => {
      if (result.status === 200) {
        toast.success(
          "You shall receive an email with the exported data shortly."
        );
        this.props.closePopup();
      } else if (result.status === 404) {
        toast.error("No data found for this date range.");
        this.setState({ disableButton: false, isLoaded: true });
      } else {
        toast.error("Something went wrong!");
        this.setState({ disableButton: false, isLoaded: true });
      }
    });
  }

  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner gateway-settings">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">Export</h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            <div className="firstpage">
              <FormGroup controlId="email" bsSize="large">
                <div className="row formfont">
                  <div className="col-md-3 formfont enable-label date-range-label">
                    Date Range
                  </div>
                  <div className="col-md-9">
                    <DateRangePicker
                      startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                      startDateId="start_date" // PropTypes.string.isRequired,
                      endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                      endDateId="end_date" // PropTypes.string.isRequired,
                      minDate={this.state.minDate}
                      maxDate={this.state.maxDate}
                      withPortal={true}
                      minimumNights={0}
                      displayFormat={() => "DD/MM/YYYY"}
                      isOutsideRange={day => {
                        return (
                          day.isBefore(moment("2020-01-01").startOf("day")) ||
                          day.isAfter(moment().endOf("day"))
                        );
                      }}
                      onDatesChange={({ startDate, endDate }) => {
                        this.setState({
                          startDate,
                          endDate,
                          disableButton: !startDate || !endDate
                        });
                        if (!startDate || !endDate) return;
                        if (!startDate.isValid() || !endDate.isValid()) return;
                      }} // PropTypes.func.isRequired,
                      focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={focusedInput => {
                        this.setState({ focusedInput });
                      }} // PropTypes.func.isRequired,
                    />
                  </div>
                </div>
              </FormGroup>
              <Button
                onClick={this.onSubmit}
                className="loginbtn1 export-btn submit-button"
                disabled={this.state.disableButton}
              >
                {this.state.isLoaded ? (
                  "Submit"
                ) : (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CustomExportCSVModal;
