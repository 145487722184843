/* eslint-disable react/prop-types */
import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";

const isAuthenticated = () => {
  const token = sessionStorage.getItem("bearer");
  if (token && token !== "") {
    return true;
  }
  return false;
};

const PrivateLoginRoute = props => {
  let routeProps = {
    path: props.path,
    component: props.component,
    exact: props.exact
  };

  return isAuthenticated() ? <Redirect to={"/"} /> : <Route {...routeProps} />;
};

export default PrivateLoginRoute;
