export const getUser = "/api/user/getUser";
export const getAllUsers = "/api/admin/getAllUsers";
export const changePassword = "/api/user/changePassword";
export const createUser = "/api/admin/createUser";
export const forgotPassword = "/api/user/forgotPassword";
export const loginPassword = "/api/user/login";
export const loginUsername = "/api/user/getUserPreLogin";
export const verifyAccount = "/api/user/verifyAccount";
export const updateUser = "/api/admin/updateUser";
export const updateStatus = "/api/admin/updateStatus";
export const deleteUser = "/api/admin/deleteUser";
export const resetPassword = "/api/user/resetPassword";
export const unblockEmail = "/api/user/sendRequestAccountUnblockEmail";
export const verifyEmail = "/api/user/resendVerificationEmail";
export const reverifyAccount = "/api/user/reVerifyAccount";
export const getSites = "/api/org/getSites";
export const getAllExamsForType = "/api/patientDashboard/getAllExamsForType";
export const getAIReportLink = "/api/patientDashboard/getAIReportLink";
export const getPdfLink = "/api/patientDashboard/getPdfLink";
export const getImages = "/api/patientDashboard/getImages";
export const getAllSiteAnalytics = "/api/analytics/getAllSiteAnalytics";
export const getUserAnalytics = "/api/analytics/getUserAnalytics";
export const getSiteAnalytics = "/api/analytics/getSiteAnalytics";
export const getTotalAnalytics = "/api/analytics/getTotalAnalytics";
export const examExportToCSV = "/api/patientDashboard/exportToCSV";
export const analyticsExportToCSV = "/api/analytics/exportToCSV";
